import React from 'react';

export default function CounterText({ text }) {
  const  newText = text || '';
  const charactersCount  = text.replace(/\s/g, '');
  return (
    <>
      Characters Count:  <b>{charactersCount.length}</b> |
      Word Count: <b>{newText.split(/\s+/).filter((element) => element.length !== 0).length}</b> |
      Line Count:<b>{newText.split("\n").filter(line => line.trim().length > 0).length}</b>
    </>
  );
}
