import React, { useState } from "react"
import { handleDownload, handleCopy } from "./covert-case";
import * as convertStyled from "../assets/css/convert-case.module.css"

const DownloadAndCopyButtons = ({ isJson, disabled, previewValue ,showMessage}) => {

  const handleDownloadClick = () => {
    handleDownload(previewValue, isJson);
    showMessage("Download completed!");
    setTimeout(() => showMessage(""), 2000); // Clear the message after 3 seconds
  };

  const handleCopyClick = () => {
    handleCopy(previewValue);
    showMessage("Text copied to clipboard!");
    setTimeout(() => showMessage(""), 2000); // Clear the message after 3 seconds
  };

  const buttonStyles = { backgroundColor: '#b5d6e6', color: '#1b1c1c', border: 'none', padding: '5px 20px', margin:'2px', borderRadius: '5px', cursor: 'pointer' };

  return (
    <div className="d-flex gap-3 py-2">
      <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary`} onClick={handleDownloadClick} disabled={disabled}>Download</button>
      <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary`} onClick={handleCopyClick}  disabled={disabled}>Copy</button>
    </div>
  );
};

export default DownloadAndCopyButtons;
