export function capitalizeSentence(sentence) {

  const wordsArray = sentence.toLowerCase().split(" ")

  const capitalizedArray = wordsArray.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  return capitalizedArray.join(" ")
}

export function sentenceType(sentence) {
  return sentence.replace(/^[a-z]/, match => match.toUpperCase())
}

export function alternativeFormatText(sentence) {
  let result = ""
  for (let i = 0; i < sentence.length; i++) {
    if (i % 2 === 0) {
      result += sentence[i].toUpperCase()
    } else {
      result += sentence[i].toLowerCase()
    }
  }
  return result
}

export function inverseCase(sentence) {
  return sentence
    .split(" ")
    .map(word => word.charAt(0).toLowerCase() + word.slice(1).toUpperCase())
    .join(" ")
}

export function handleCopy(previewValue) {
  navigator.clipboard.writeText(previewValue)
    .then(() => {
      console.log("Text copied to clipboard successfully!")
    })
    .catch((err) => {
      console.error("Failed to copy text to clipboard:", err)
    })
}

export function handleDownload(previewValue, isJson) {
  const element = document.createElement("a")
  const file = new Blob([previewValue], { type: "text/plain;charset=utf-8" })
  element.href = URL.createObjectURL(file)
  if(isJson) {
    element.download = "downloaded_text.json"
  } else {
    element.download = "downloaded_text,txt"
  }
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}


export function upsideDownText(text) {
  const upsideDownChars = {
    a: "ɒ",
    b: "q",
    c: "ɔ",
    d: "p",
    e: "ǝ",
    f: "ɟ",
    g: "ƃ",
    h: "ɥ",
    i: "ı",
    j: "ɾ",
    k: "ʞ",
    l: "ʃ",
    m: "ɯ",
    n: "u",
    o: "o",
    p: "d",
    q: "b",
    r: "ɹ",
    s: "s",
    t: "ʇ",
    u: "n",
    v: "ʌ",
    w: "ʍ",
    x: "x",
    y: "ʎ",
    z: "z",
    A: "∀",
    B: "𐐒",
    C: "Ɔ",
    D: "ᗡ",
    E: "Ǝ",
    F: "Ⅎ",
    G: "פ",
    H: "H",
    I: "I",
    J: "ſ",
    K: "ʞ",
    L: "˥",
    M: "W",
    N: "N",
    O: "O",
    P: "Ԁ",
    Q: "Ό",
    R: "ᴚ",
    S: "S",
    T: "⊥",
    U: "∩",
    V: "Λ",
    W: "M",
    X: "X",
    Y: "⅄",
    Z: "Z",
    " ": " "
  }

  return text
    .split("")
    .map(char => upsideDownChars[char] || char)
    .reverse()
    .join("")
}

export function addSpacing(text) {
  return text.split("").join(" ")
}

export function addStrikethroughEffect(text) {
  const combiningChar = "\u0336"
  return [...text].map(letter => letter + combiningChar).join("")
}

export function generateBackwardsText(text) {
  return text.split("").reverse().join("")
}

export function generateBoldText(text) {
  const boldChars = {
    a: "𝐚", b: "𝐛", c: "𝐜", d: "𝐝", e: "𝐞", f: "𝐟", g: "𝐠", h: "𝐡", i: "𝐢", j: "𝐣", k: "𝐤", l: "𝐥", m: "𝐦",
    n: "𝐧", o: "𝐨", p: "𝐩", q: "𝐪", r: "𝐫", s: "𝐬", t: "𝐭", u: "𝐮", v: "𝐯", w: "𝐰", x: "𝐱", y: "𝐲", z: "𝐳",
    A: "𝐀", B: "𝐁", C: "𝐂", D: "𝐃", E: "𝐄", F: "𝐅", G: "𝐆", H: "𝐇", I: "𝐈", J: "𝐉", K: "𝐊", L: "𝐋", M: "𝐌",
    N: "𝐍", O: "𝐎", P: "𝐏", Q: "𝐐", R: "𝐑", S: "𝐒", T: "𝐓", U: "𝐔", V: "𝐕", W: "𝐖", X: "𝐗", Y: "𝐘", Z: "𝐙"
  }

  return text.split("").map(char => boldChars[char] || char).join("")
}


export function generateItalicText(text) {
  const italicChars = {
    a: "𝘢", b: "𝘣", c: "𝘤", d: "𝘥", e: "𝘦", f: "𝘧", g: "𝘨", h: "𝘩", i: "𝘪", j: "𝘫", k: "𝘬", l: "𝘭", m: "𝘮",
    n: "𝘯", o: "𝘰", p: "𝘱", q: "𝘲", r: "𝘳", s: "𝘴", t: "𝘵", u: "𝘶", v: "𝘷", w: "𝘸",
    x: "𝘹", y: "𝘺", z: "𝘻",
    A: "𝘈", B: "𝘉", C: "𝘊", D: "𝘋", E: "𝘌", F: "𝘍", G: "𝘎", H: "𝘏", I: "𝘐", J: "𝘑",
    K: "𝘒", L: "𝘓", M: "𝘔",
    N: "𝘕", O: "𝘖", P: "𝘗", Q: "𝘘", R: "𝘙", S: "𝘚", T: "𝘛", U: "𝘜", V: "𝘝", W: "𝘞",
    X: "𝘟", Y: "𝘠", Z: "𝘡"
  }
  return text.split("").map(char => italicChars[char] || char).join("")
}

export function generateSlugifyURL(text) {

  return text.toLowerCase() // Convert to lowercase
    .replace(/[^\w\s]/g, "") // Remove special characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .trim() // Remove leading and trailing spaces
}

export function generateSnackCase(text) {
  return text.toLowerCase() // Convert to lowercase
    .replace(/[^\w\s]/g, "") // Remove special characters
    .replace(/\s+/g, "_") // Replace spaces with underscores
    .trim() // Remove leading and trailing spaces
}

export function generatePascalCase(text) {
  return text
    .replace(/[!@#$%^&*()_+\-=\[\];'\/.,<>?:{}"]/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase()).replace(/\s+/g, "") // Convert to PascalCase
}


export function generateCamelCase(text) {
  return text
    .replace(/[!@#$%^&*()_+\-=\[\];'\/.,<>?:{}"]/g, " ")
    .replace(/\b\w/g, (match) => match.toLowerCase())
    .replace(/\s+(.)/g, (match, group1) => group1.toUpperCase()); // Convert to camelCase
}



