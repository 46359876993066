import React from "react"
import * as convertStyled from "../assets/css/convert-case.module.css"


function CopiedMessage({message}) {
  return (
    <div  className={convertStyled.copy_message}>{message}</div>
  )
}

export default CopiedMessage