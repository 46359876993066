import React from "react"

// Shape Images
import Shape1 from "../../assets/images/shape1.png"
import Shape2 from "../../assets/images/shape2.svg"
import Shape3 from "../../assets/images/shape3.svg"
import Shape4 from "../../assets/images/shape4.svg"
import Shape5 from "../../assets/images/shape5.png"
import {Link} from "gatsby";

const Breadcrumbs = ({ pageTitle, parent = [] }) => {
  return (
      <div className="d-table" style={{ marginBottom: '20px' }}>
        <div className="d-table-cell">
            <p className={"breadcrumb-text"}>{parent.map((p, index) => {
              return <Link className={"breadcrumb-parent"} key={index} to={`/${p.link}/`}>{p.name + ' /'}</Link>
            })}<span style={{marginLeft: 5}}>{pageTitle}</span></p>
        </div>
      </div>
  )
}

export default Breadcrumbs
