import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Trusted from "../../assets/icons/trusted.png"
import Star from "../../assets/icons/star.png"

const query = graphql`
  {
    strapiPartner {
      subTitle
      helperText
      btnText
      btnLink
      infoText
      infoLink
      partnerTitle
      partnerLogo {
        id  
        mainImg {
          childImageSharp {
            fluid(webpQuality: 100, jpegProgressive:true, jpegQuality: 100,maxHeight:100 ) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
            hoverImg:fluid(grayscale:true, webpQuality: 100, jpegProgressive:true, jpegQuality: 100, maxHeight:100 ) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        # hoverImg {
        #   childImageSharp {
        #     fluid {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
        link
      }
    }
  }
`

const Partner = () => {
  const data = useStaticQuery(query)
  const { strapiPartner } = data

  return (
    <>
      <div className="ready-to-talk">
        <div className="container">
          <h3>{strapiPartner.subTitle}</h3>
          <p>{strapiPartner.helperText}</p>

          <Link to={strapiPartner.btnLink} className="btn btn-primary">
            {strapiPartner.btnText}
          </Link>

          {/*<span>*/}
          {/*  <a href={strapiPartner.infoLink}>{strapiPartner.infoText}</a>*/}
          {/*</span>*/}
        </div>
      </div>

      <div className="partner-area partner-section">

        <div className="container">
          <div className="section-title mt-5">
            <h2>You are in a good company</h2>
          </div>
          <div className="partner-inner">
            <div className="row justify-content-center">
              {strapiPartner.partnerLogo.map(pLogo => (
                <div className="col-lg-2 col-md-3 col-6 align-middle" key={pLogo.id}>
                  <a target="_blank" rel="noreferrer">
                    <img
                      src={pLogo.mainImg.childImageSharp.fluid.src}
                      alt="partner"
                    />
                    <img
                      src={pLogo.mainImg.childImageSharp.hoverImg.src}
                      alt="partner"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Partner
